import axios from "axios";
// import Vue from 'vue'
//const BASE_URL = "http://localhost:5000/fatourti/";
//const BASE_URL = "https://paysmart.monetiquetunisie.com/express"
//const BASE_URL = "https://staging.fatourti.we-settle.com//express"
const BASE_URL = process.env.VUE_APP_EXPRESS_APP_URL;
export async function fetchApi(method, url, _data, commit) {
  console.error("fetch");
  console.error("_data", _data);
  console.log("url", url);
  const [data, params] = method == "GET" ? [null, _data] : [_data, null];
  commit("setBusy", true);
  console.log("baseUrl",BASE_URL + "/api",url,data,params);
  return axios({
    method,
    baseURL: BASE_URL + "/api",
    // withCredentials: true,
    url,
    data,
    params,
  })
    .then((res) => {
      commit("setBusy", false);
      const { done, status } = res.data;
      console.error(status);
      if (!done && status == 401) {
        commit("setUnauthorized", true);
        return { done };
      }
      return res.data;
    })
    .catch((err) => {
      commit("setBusy", false);
      throw err;
    })
    .catch((err) => {
      commit("setBusy", false);
      throw err;
    });
}
export function windowOpen(url) {
  return window.open(BASE_URL + url);
}
