<template>
  <section id="videoteque" class="pt-5 container">
    <h2>Vidéotèque</h2>
    <div id="videocarousel" class="carousel slide" data-interval="false">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#videocarousel"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#videocarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row" style="padding-bottom: 40px !important">
            <div class="col-lg-8">
              <iframe
                id="video"
                width="100%"
                height="600"
                :src= "PAYSMARTVIDEO1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div class="col-lg-4 d-flex flex-column align-self-center">
              <h6 class="text-center">
                {{ $t("videoteque.h1") }}
              </h6>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p11") }}
              </p>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p12") }}
              </p>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p13") }}
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div class="carousel-item">
          <div class="row" style="padding-bottom: 40px !important">
            <div class="col-lg-8">
              <iframe
                width="100%"
                height="600"
                :src="PAYSMARTVIDEO2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div class="col-lg-4 d-flex flex-column align-self-center">
              <h6 class="text-center">
                {{ $t("videoteque.h2") }}
              </h6>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p21") }}
              </p>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p22") }}
              </p>
              <p class="text-justify text-muted">
                {{ $t("videoteque.p23") }}
              </p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      PAYSMARTVIDEO1: process.env.VUE_APP_PAYSMARTVIDEO1,
      PAYSMARTVIDEO2: process.env.VUE_APP_PAYSMARTVIDEO2
    };
  },
  methods: {
    pause() {
      const videos = document.querySelectorAll("iframe");
      if (videos.length !== 0) {
        videos.forEach((i) => {
          const source = i.src;
          i.src = source;
        });
      }
    },
  },
};
</script>

<style scoped></style>
