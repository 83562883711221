<template>
  <!-- Begin Testimonial Area -->
  <section class="reclamation d-flex align-items-center" id="reclamation" style="min-height: 478px;">
      <div class="container d-flex align-items-center" style="max-width: 1128px;
        min-height: 320px;
        border-radius: 8px;
        background-color: #337fa8;">
  <div class="row">
  <div class="col-md-8 d-flex flex-column align-items-center">
         <h2 class="text-white">{{ $t("section4.titre") }}</h2>
         <p 
         class="claim-p"
          style="font-size: 16px;
          text-align: center;
          line-height: 22px;
          color: #fbfbfc;
          font-weight: 400;
          font-family: 'Source Sans Pro'">
          {{ $t("section4.notif") }}
          </p>
            <p 
         class="claim-p"
          style="font-size: 16px;
          text-align: center;
          line-height: 22px;
          color: #fbfbfc;
          font-weight: 400;
          font-family: 'Source Sans Pro'">
          {{ $t("section4.rec") }}
          </p>
        </div>
        <div
          class="col-md-4 d-flex flex-column align-items-center justify-content-center"
        >
      <button 
        @click="createAccount()"
        class="btn-claim" >
        {{ $t("section1.compte") }}
      </button>
          <v-dialog v-model="dialogCompose" max-width="600">
            <form
              action="/ma-page-de-traitement"
              method="post"
              class="custom-reclamation-form postition-relative"
              style="
                overflow-y: hidden !important;
                background-color: #fff !important;
                padding: 25px;
                margin: 0 !important;
              "
            >
              <v-icon
                @click="saveDraft"
                class="postion-absolute top-0 end-0"
                style="float: right; color: #1c93b4 !important"
                >mdi-close-circle</v-icon
              >
              <div class="row">
                <span class="h3 text-center" style="color: #1c93b4 !important">
                  {{ $t("section4.titre") }}</span
                >
                <p class="text-center">
                  {{ $t("section4.notif") }}
                </p>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {{ $t("section4.Facturier") }} <span>*</span>
                    </label>
                    <select name="facturier" id="facturier">
                      <option value="">{{ $t("section4.Facturier") }}</option>
                    </select>
                    <img
                      src="@/assets/images/faq-arrow.png"
                      class="selectcustom-arrow"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6 custom-form-col">
                      <div class="form-group">
                        <label>
                          {{ $t("section4.Reference") }} <span>*</span>
                        </label>
                        <select name="reference" id="reference">
                          <option value="">
                            {{ $t("section4.Reference") }}
                          </option>
                        </select>
                        <img
                          src="@/assets/images/faq-arrow.png"
                          class="selectcustom-arrow"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 custom-form-col custom-form-right-col">
                      <div class="form-group">
                        <label> </label>
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.Fsp") }} </label>
                    <select name="fournisseur" id="fournisseur">
                      <option value="">{{ $t("section4.Fsp") }}</option>
                    </select>
                    <img
                      src="@/assets/images/faq-arrow.png"
                      class="selectcustom-arrow"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.SolutionP") }} </label>
                    <select name="solution" id="solution">
                      <option value="">{{ $t("section4.SolutionP") }}</option>
                    </select>
                    <img
                      src="@/assets/images/faq-arrow.png"
                      class="selectcustom-arrow"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.modeP") }} <span>*</span> </label>
                    <select name="mode" id="mode">
                      <option value="">{{ $t("section4.modeP") }}</option>
                    </select>
                    <img
                      src="@/assets/images/faq-arrow.png"
                      class="selectcustom-arrow"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.natureR") }} <span>*</span> </label>
                    <select name="nature" id="nature">
                      <option value="">{{ $t("section4.natureR") }}</option>
                    </select>
                    <img
                      src="@/assets/images/faq-arrow.png"
                      class="selectcustom-arrow"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label> {{ $t("section4.ObjetR") }} <span>*</span> </label>
                    <input type="text" />
                  </div>
                  <div class="form-group">
                    <label> {{ $t("section4.pieceJ") }} </label>
                    <div class="file-input">
                      <input
                        type="file"
                        name="file-input"
                        id="file-input"
                        class="file-input__input"
                      />
                      <label class="file-input__label" for="file-input">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="upload"
                          class="svg-inline--fa fa-upload fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                          ></path>
                        </svg>
                        <span>{{ $t("section4.uploadFile") }}</span></label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {{ $t("section4.descriptifR") }} <span>*</span>
                    </label>
                    <textarea name="description" id="description"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.phone") }} <span>*</span> </label>
                    <input type="tel" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{ $t("section4.email") }} <span>*</span> </label>
                    <input type="mail" />
                  </div>
                </div>
                <div class="col-12 text-center">
                  <button class="submit-form" type="submit">
                    {{ $t("section4.envoyer") }}
                  </button>
                </div>
              </div>
            </form>
          </v-dialog>
        </div>
      </div>
      </div>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
// import PaimentModal from "../components/paiment/PaimentModal.vue";
import { mapActions, mapGetters } from "vuex";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldHandle from "vuetable-2/src/components/VuetableFieldHandle.vue";
import _ from "lodash";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { VueRecaptcha, Vuetable, VuetablePagination },
  created() {
    if (!localStorage.getItem("invoices")) localStorage.setItem("invoices", []);
  },
  data() {
    return {
      loginUrl: process.env.VUE_APP_LOGIN_URL,
      dialogCompose: false,
      payDialog: false,
      load: false,
      detailDialog: false,
      headers: [
        {
          text: "Date de création",
          align: "center",
          value: "invoiceDate",
        },
        { text: "Date échéance", align: "center", value: "invoiceExpiryDate" },
        { text: "Facturier", align: "center", value: "billerRs" },
        { text: "Référence", align: "center", value: "invoiceRef" },
        { text: "Montant", align: "center", value: "invoiceAmount" },
        { text: "Etat", align: "center", value: "paymentStatus" },
        { text: "Actions", align: "center", value: "Actions" },
      ],
      desserts: [],
      dialog: false,
      searchDealg: false,
      fields: [
        {
          name: VuetableFieldHandle,
        },
        {
          name: "invoiceDate",
          title: "Date de création",
          sortField: "invoiceDate",
        },
        {
          name: "invoiceExpiryDate",
          title: "Date échéance",
          sortField: "invoiceExpiryDate",
        },

        {
          name: "billerRs",
          title: "Facturier",
          sortField: "billerRs",
        },
        {
          name: "invoiceRef",
          title: "Référence",
          sortField: "invoiceRef",
        },

        {
          name: "invoiceAmount",
          title: "Montant",
          sortField: "invoiceAmount",
        },
        {
          name: "paymentStatus",
          title: "Etat",
          sortField: "paymentStatus",
        },
        "actions",
      ],
      perPage: 5,
      data: [],
      search: "invoiceRef",
      qrcode: "Hello World!",
      idCaptcha: process.env.VUE_APP_RECAPTCHA_KEY,
      total: 0,
      display: true,
      invoiceData: {},
      title: "test",
      showModal: false,
      form: {
        robot: false,
      },
      new: false,
      isCard: false,
      invoiceRef: "",
      desc: false,
      defaultSelected: {
        title: "steg",
        cardImage: "@/assets/images/partner/steg.png",
        billet: 701,
        text: "Référence de l’abonnement",
      },
      isVisible: false,
      mainBg: require("@/assets/images/bg4.png"),
      options: [
        {
          title: "steg",
          cardImage: require("@/assets/images/partner/steg.png"),
          billet: 701,
          text: "Référence de l’abonnement",
        },
        {
          title: "sonede",
          cardImage: require("@/assets/images/partner/sonde.png"),
          billet: 502,
          text: "Code client/Police/Référence facture",
        },
      ],
    };
  },
  watch: {
    data(newVal, oldVal) {
      console.log(newVal + oldVal);
      this.$refs.vuetable.refresh();
    },
  },
  // mounted() {
  //   this.desserts = JSON.parse(localStorage.getItem("invoices"));
  // },
  methods: {
    createAccount() {
      window.open(this.loginUrl + this.$i18n.locale + "/register");
    },
    compose() {
      this.dialogCompose = true;
    },
    saveDraft() {
      this.dialogCompose = false;
    },
    openClaiming() {
      this.$modal.show("claiming");
    },
    closeClaiming() {
      this.$modal.hide("claiming");
    },
    closeSearch() {
      this.searchDealg = false;
    },
    closeList() {
      this.dialog = false;
    },
    closeDescr() {
      this.desc = false;
      this.detailDialog = false;
      this.dialog = true;
    },
    closePaiment() {
      this.payDialog = false;
      if (this.desc) this.detailDialog = true;
      else this.dialog = true;
    },
    deleteAll() {
      this.resetLocalStorage();
      this.resetCart();
      this.desserts = [];
    },
    newSearch() {
      this.dialog = false;
      document.getElementById("testimonial").scrollIntoView();
    },
    getTotalPrix() {
      try {
        const invoiceData = JSON.parse(localStorage.getItem("invoices"));
        console.log(invoiceData);
        var sum = 0;
        for (var i = 0; i < invoiceData.length; i++) {
          sum += Number(invoiceData[i].invoiceAmount);
        }
        return parseFloat(sum / 1000).toFixed(3);
      } catch (e) {
        return 0;
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log("pagination:", pagination);
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    getImage(billet) {
      if (billet == "502") return require("@/assets/images/partner/sonde.png");
      else return require("@/assets/images/partner/steg.png");
    },
    ...mapGetters([
      "cards",
      "getInvoices",
      "getError",
      "getNewInvoices",
      "getSubCategory",
    ]),
    ...mapActions([
      "addItemsToPayment",
      "openPaymentWindow",
      "setItem",
      "setInvoice",
      "setSubCategory",
      "responseCaptcha",
      "fetchInvoices",
      "getFromLocalStorage",
      "removeItemFromCards",
      "updateInvoices",
      "getInvoiceById",
      "resetLocalStorage",
      "resetCart",
    ]),

    pagination(p) {
      this.length = p.itemsLength;
    },
    DeleteInvoices() {
      this.resetLocalStorage();
      this.resetCart();
      this.isVisible2 = false;
      this.display = false;
      this.$vm2.close("modal2");
      this.isVisible1 = false;
      this.$vm2.close("modal1");
    },
    getName(name) {
      console.log("Bienvenue" + name);
      return "Bienvenue " + name;
    },
    getDate(date) {
      if (!date) return "";
      const currentDate = new Date(date);
      if (!isNaN(currentDate)) {
        return (
          this.fixDigits(currentDate.getDate()) +
          "/" +
          this.fixDigits(currentDate.getMonth() + 1) +
          "/" +
          this.fixDigits(currentDate.getFullYear())
        );
      }
    },
    getMontant(money) {
      return parseFloat(money / 1000).toFixed(3);
    },
    getNumFact(num) {
      return "Détails facture " + num;
    },
    fixDigits(digits) {
      if (parseFloat(digits) < 10) return "0" + digits;
      return digits;
    },
    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    handleClose() {
      this.isVisible1 = false;
      this.$vm2.close("modal1");
      this.isVisible2 = true;
      this.display = true;
      this.$vm2.open("modal2");
    },
    handleCloseDesc() {
      this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
      this.data = this.invoiceData;
      this.isVisible4 = false;
      this.$vm2.close("modalDesc");
      this.isVisible2 = false;
      this.display = false;
      this.$vm2.close("modal2");
      this.isVisible1 = true;
      this.$vm2.open("modal1");
    },
    handleClosePay() {
      this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
      this.data = this.invoiceData;
      this.isVisible3 = false;
      this.$vm2.close("modalPaiement");
      this.display = false;
      this.isVisible2 = false;
      this.$vm2.close("modal2");
      this.isVisible1 = true;
      this.$vm2.open("modal1");
    },
    open() {
      this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
      this.desserts = this.invoiceData;
      this.searchDealg = false;
      this.dialog = true;
    },
    referechdata() {
      this.$swal(
        "Suppression Réussie",
        "La suppression est terminée avec succès",
        "success"
      );
      this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
      this.desserts = this.invoiceData;
    },
    openDetail(id) {
      this.desc = true;
      this.invoiceData = JSON.parse(localStorage.getItem("invoices")).filter(
        (el) => el.id === id
      )[0];
      console.log(this.invoiceData);
      const payment = this.invoiceData.acceptedPaymentModes.filter(
        (el) => el.paymentMode == "W"
      )[0];
      this.qrcode = payment.destinationAccount;
      console.log(this.invoiceData);
      this.dialog = false;
      this.detailDialog = true;
    },
    openPaiment(paymentId, invoiceRef) {
      console.log("test");
      const isShow = true;
      console.log("isShow :>> ", isShow);
      console.log("invoiceRef :>> ", invoiceRef);
      let List = [];
      List.push({ id: paymentId });
      localStorage.setItem("invoiceId", JSON.stringify(List));
      if (isShow) {
        this.getInvoiceById(paymentId);
        console.log("èèèèèèèèèè");
        const payMethod = JSON.parse(localStorage.getItem("invoices")).filter(
          (el) => el.id === paymentId
        )[0].acceptedPaymentModes;
        console.log(`paymentMethod`, payMethod);
        this.isCard =
          payMethod?.filter((el) => el.paymentMode === "C").length > 0;
        this.dialog = false;
        this.payDialog = true;
      }
    },
    openPaimentDesc(paymentId, invoiceRef) {
      console.log("test");
      const isShow = true;
      console.log("isShow :>> ", isShow);
      console.log("invoiceRef :>> ", invoiceRef);
      let List = [];
      List.push({ id: paymentId });
      localStorage.setItem("invoiceId", JSON.stringify(List));
      if (isShow) {
        this.getInvoiceById(paymentId);
        console.log("èèèèèèèèèè");
        const payMethod = JSON.parse(localStorage.getItem("invoices")).filter(
          (el) => el.id === paymentId
        )[0].acceptedPaymentModes;
        console.log(`paymentMethod`, payMethod);
        this.isCard =
          payMethod?.filter((el) => el.paymentMode === "C").length > 0;
        this.detailDialog = false;
        this.payDialog = true;
      }
    },
    async openWindow(method, paymentMean) {
      this.load = true;
      console.log("ssssssss-------------");
      console.log(method);
      console.log("paymentMean-------------");
      console.log(paymentMean);
      console.log(localStorage.getItem("invoiceId"));
      localStorage.setItem("start", false);
      const id = JSON.parse(localStorage.getItem("invoiceId"));
      await this.addItemsToPayment({
        invoiceId: id[0].id,
        paymentMean: paymentMean,
      });
      this.openPaymentWindow(method);
      this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
      this.payDialog = false;
      this.dialog = true;
      this.load = false;
      const timer = setInterval(async function () {
        if (localStorage.getItem("start")) {
          this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
          this.desserts = this.invoiceData;
          clearInterval(timer);
        }
      }, 500);
    },
    handleClose2() {
      this.isVisible2 = false;
      this.display = false;
      this.$vm2.close("modal2");
      if (this.new) {
        this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
        this.data = this.invoiceData;
        this.display = false;
        this.$vm2.open("modal1");
        this.new = false;
      }
    },
    open2(type) {
      this.display = true;
      console.log(type);
      console.log(type == "steg");
      if (type == "steg") this.defaultSelected = this.options[0];
      else this.defaultSelected = this.options[1];
      try {
        this.$refs.recaptcha.reset();
      } catch (e) {
        console.log(this.defaultSelected);
      }
      this.searchDealg = true;
    },
    openNew() {
      this.new = true;
      this.display = true;
      this.invoiceRef = "";
      //this.defaultSelected=this.options[0];
      this.isVisible1 = false;
      this.$vm2.close("modal1");
      this.isVisible2 = true;
      this.$vm2.open("modal2");
      this.$refs.recaptcha.reset();
    },


    getColorEtat(etat) {
      if (etat == "PAID") return "background:#A4C803;color:#fff";
      else return "background:#F24F04;color:#fff";
    },
    getString(etat) {
      if (etat == "invoiceRef") return "Référence facture";
      else if (etat == "clientCode") return "Code Client";
      else return "Contract(Police)";
    },
    getNameEtat(etat) {
      if (etat == "PAID") return "Payée";
      else return "Impayée";
    },
    expiredCaptcha() {
      this.captcha = false;
      this.responseCaptcha("");
    },
    goToPro() {
      const PAYMENT_URL = process.env.VUE_APP_LOGIN_URL;
      window.open(PAYMENT_URL);
    },
    async goToDetail() {
      console.log(this.search);
      this.load = true;
      //const query = buildQuery(this.defaultSelected.title, this.invoiceRef);
      let query = "";
      switch (this.search) {
        case "contractNumber":
          query = { contractNumber: this.invoiceRef };
          break;
        case "clientCode":
          query = { clientCode: this.invoiceRef };
          break;
        default:
          query = { invoiceRef: this.invoiceRef };
          break;
      }
      const error = await this.fetchInvoices({
        subCategory: this.defaultSelected.billet,
        query,
      });
      if (!error) {
        this.load = false;
        this.open();
      } else {
        this.expiredCaptcha();
        this.load = false;
        if (error.type == "error") {
          switch (error.message) {
            case "Not Found":
              return this.$toast.error("Aucune facture ou Abonnement trouvée");
            case "No invoices found":
              return this.$toast.error("Aucune facture ou Abonnement trouvée");
            case "Service Unavailable":
              return this.$toast.error(
                "Le système du facturier " +
                  this.defaultSelected.title +
                  " est temporairement indisponible. Veuillez réessayer plus tard"
              );
            case "Internal Server error":
              return this.$toast.error(
                "Le système du facturier " +
                  this.defaultSelected.title +
                  " est temporairement indisponible. Veuillez réessayer plus tard"
              );
            case "Bad Request" || "Invalid search parameters":
              return this.$toast.error(
                "Critère(s) de recherche invalide(s). Veuillez réessayer"
              );

            default:
              return this.$toast.error(error.message);
          }
        } else {
          this.$toast.info(error.message);
        }
      }
    },
  },
};
// function createSearchkeySonede(sonedId) {
//   if (/^[0-9]{16}$/.test(sonedId)) return "invoiceRef";
//   if (/[0-9]{3}1[A-Z]{1}[0-9]{7}/.test(sonedId)) return "clientCode";
//   if (/^[0-9]{8}$/.test(sonedId)) return "contractNumber";
// }
// function buildQuery(biller, search) {
//   console.log(biller);
//   switch (biller) {
//     case "steg": {
//       const query = { invoiceRef: search };
//       return query;
//       // return {
//       //   invoiceRef: _vm.invoiceRef,
//       //  // clienCin: _vm.clientCin,
//       // };
//     }
//     case "sonede": {
//       console.log("soneeeede", createSearchkeySonede(search));
//       const query = { [createSearchkeySonede(search)]: search };
//       return query;
//     }
//     default:
//       break;
//   }
// }
</script>
<style scoped>
th {
  color: rgba(17, 15, 36, 0.4);
}

.vm-backdrop {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.mod {
  /* Auto layout */
  margin-top: 15%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 411px;
  height: 470px;
  left: calc(50% - 411px / 2 + 0.5px);
  top: calc(50% - 470px / 2 - 0.5px);
  /* Shadoc/Huge */

  box-shadow: 0px 4px 40px rgba(16, 16, 16, 0.05);
  border-radius: 12px;
}

.subTest {
  width: 311px;
  height: 16px;

  /* Regular/M */

  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Black (Muted) */

  color: rgba(17, 15, 36, 0.4);

  mix-blend-mode: normal;
}

.testimonial-style-3 {
  height: 985px;
  background-size: 100% 100% !important;
}

.title {
  font-weight: bold !important;
  font-size: 30px !important;
  color: #5eb8d4 !important;
}

.feature-item {
  background-color: #fff;
  height: 235px;
  width: 235px;
  padding-top: 25px;
  padding-bottom: 22.5px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 45px;
  cursor: pointer;
}

.espace {
  background-color: transparent !important;
}

.imgDiv {
  width: 75px;
}

.col-sm {
  margin-bottom: 2%;
}

.btnTest {
  /* Button */
  border: none;
  color: white;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  gap: 4px;

  width: 200px;
  height: 48px;
  /* Primaire */

  background: #1999c2;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.table > :not(:first-child) {
  border-top: 1px solid #ececee !important;
}

.choose-meth-icon img {
  width: 72px;
  max-height: 72px;
}

.choose-meth:hover .choose-meth-icon {
  border: 3px solid #fff;
}

.choose-meth-icon {
  border-radius: 12px;
  margin: 6px auto;
  transition: 0.4s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
}

.colColor {
  color: rgba(17, 15, 36, 0.4);
}

.btnIcon {
  cursor: pointer;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;

  width: 58px;
  height: 16px;

  /* Red */

  background: #f24f04;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.inputStyle {
  padding-left: 8px;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  background: #ffffff;
  align-items: center;
  height: 40px !important;
}

button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.item {
  margin-left: 2%;
  cursor: pointer;
}

.active {
  color: gray;
}
.v-dialog.v-dialog--active {
  overflow: hidden;
}
@media (max-width: 1000px) {
  .claim-title {
    text-align: center;
    font-size: 44px !important;
  }
  .btn-claim{
    margin-bottom:40px
  }
}
</style>
