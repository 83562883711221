<template>
  <v-app>
    <div v-if="pathName == '/express/chatbot'"></div>
    <div class="wrapper" v-if="pathName !== '/express/chatbot'">
      <HeaderSection></HeaderSection>
      <FeatureSection style="padding-top: 80px !important;"></FeatureSection>
      <Videoteque></Videoteque>
      <FAQ></FAQ>
      <Partner></Partner>
      <TestimonialThree></TestimonialThree>
      <FooterSectionThree></FooterSectionThree>
    </div>
  </v-app>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue";
import FAQ from "@/components/faq.vue";
import TestimonialThree from "@/components/TestimonialThree.vue";
import FooterSectionThree from "@/components/FooterSectionThree.vue";
import FeatureSection from "@/components/FeatureSection.vue";
import Partner from "@/components/partner.vue";
import Videoteque from "@/components/Videoteque.vue";
export default {
  components: {
    Partner,
    FeatureSection,
    Videoteque,
    TestimonialThree,
    FooterSectionThree,
    HeaderSection,
    FAQ
  },
  data() {
    return {
      videoBannerBg: "@/assets/images/migrate/bg/1-4-1920x1408.png",
    };
  },
  computed: {
    pathName() {
      return window.location.pathname;
    },
  },
};
</script>
<style lang="scss">
.notificationBell .notification .notification-icon {
  cursor: pointer !important;
}
.nav-link {
  color: #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #48b4ee;
  scrollbar-width: thin;
  z-index: 999 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #337fa8;
}

.notificationBell .notification .notification-counter {
  position: absolute !important;
  top: 0px !important;
  left: calc(100% - 10px) !important;
  border-radius: 100% !important;
  background-color: #e51f32 !important;
  padding: 3px !important;
  font-size: 12px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #337fa8 !important;
}
.swal2-title {
  color: #337fa8 !important;
}
.v-data-footer__pagination{
display: none !important;
}
.swal2-container.swal2-center>.swal2-popup {
    border-radius: 14px !important;
}
</style>
