export default {
  state: {
    cards: [],
    inCard: false,
  },
  getters: {
    cards: (state) => state.cards,
  },
  mutations: {
    setCards(state, data) {
      console.log("data lowlaa :>> ", data);
      if (state.inCard) {
        console.log("data :>> ", data);
        state.cards = data;
      } else {
        state.cards = data.filter((el) => el.paymentStatus != "PAID");
      }
    },
    addItemToCards(state, data) {
      for (const element of data) {
        const index = state.cards.findIndex((el) => {
          return el.key.trim() == element.key.trim();
        });
        console.warn(element.key);
        console.warn(index);

        if (index != -1) {
          state.cards.splice(index, 1);
        }
        state.cards.push(element);
      }
    },
    removeItemFromCards(data) {
      let invoiceData = JSON.parse(localStorage.getItem("invoices"));
      let index=invoiceData.findIndex(i=>i.id === data.id)
      let newInvoices=invoiceData.splice(index, 1);
      localStorage.setItem("invoices", JSON.stringify(newInvoices));
    },

    resetLocalStorage() {
      localStorage.removeItem("invoices")
    },
    removeRechargeFromCards(data) {
      let rechargeData = JSON.parse(localStorage.getItem("recharges"));
      let index=rechargeData.findIndex(i=>i.id === data.id)
      let newRechargeData=rechargeData.splice(index, 1);
    localStorage.setItem("recharges", JSON.stringify(newRechargeData));
    },

    resetRechargeLocalStorage() {
      localStorage.setItem("recharges", []);
    },
    updateItem(state, data) {
      console.log("true :>> ", true);
      const index = state.cards.findIndex((el) => {
        return el.key === data.key;
      });
      console.log("index :>> ", index);

      state.cards[index] = data;
    },
    setIsInCard(state, data) {
      state.inCard = data;
    },
    resetCart(state) {
      state.cards = [];
    },
  },
  actions: {
    setCards({ commit }, payload) {
      console.log("payload :>> ", payload);
      commit("setCards", payload);
      // dispatch("persistCarts");
      // commit('')
    },
    resetCart({ commit }) {
      commit("resetCart");
    },
    addItemToCards({ commit, state }, payload) {
      const cart = {};
      for (const invoice of state.cards) {
        cart[invoice.key] = invoice;
      }
      for (const invoice of payload) {
        cart[invoice.key] = invoice;
      }

      commit("setCards", Object.values(cart));
      // dispatch("persistCarts");
    },
    isIncard({ commit, state}, payload) {
      commit("setIsInCard", payload);
      if (!payload) {
        // const data = state.cards.filter(el => el.status !== 'paid')
        commit("setCards", state.cards);
        // dispatch("persistCarts");
      }
    },
    persistCarts({ state }) {
      const newIds = state.cards
        .filter((el) => el.paymentStatus !== "PAID")
        .map((el) => el.id);
      // const newIds = state.cards.map(el => el.id);
      console.log("hiiii :>> ", state.cards);
      window.localStorage.setItem("invoices", JSON.stringify(state.cards));
      window.localStorage.setItem("card", JSON.stringify(newIds));
    },

    updateItem({ commit }, payload) {
      commit("updateItem", payload);
    },
    // removeOneItem({ commit }, payload) {
    //   commit("removeItemFromCards", payload);
    //   // dispatch("persistCarts");
    // },
    // removeAll({ commit}) {
    //   commit('resetLocalStorage')
    //   // commit('resetCart')
    // },
    // // removeOneRecharge({ commit }, payload) {
    // //   commit("removeRechargeFromCards", payload);
    // //   // dispatch("persistCarts");
    // // },
    // removeAllRecharges({ commit}) {
    //   commit('resetRechargeLocalStorage')
    //   // commit('resetCart')
    // },
  },
};
