import Vue from "vue";
import App from "./App.vue";
import store from "./store";
// import VM2 from "./index.js";
import vSelect from "vue-select";
import "../src/assets/css/icofont.min.css";
import "../src/assets/css/gordita-font.css";
import "../src/assets/scss/style.scss";
import "vue-select/dist/vue-select.css";
import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { faHatWizard } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {  faCreditCard } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueQRCodeComponent from "vue-qrcode-component";
import loader from "vue-ui-preloader";
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueCarousel from 'vue-carousel';
// import VueAnalytics from "vue-analytics";

import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueGtag from "vue-gtag";

const devmode = process.env.VUE_APP_DEV_MODE;
if(devmode==1)
{
  console.info = function () { };
  console.log = function () { };
  console.warn = function () { };
  console.error = function () { };
}

if (devmode == 1) {
  Vue.use(VueGtag, {
    config: { id: 'G-P279CQZJD2' } 
  });}
Vue.use(VueCarousel);
Vue.use(VueSweetalert2);
Vue.use(loader);
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(VueToast, {
  // One of the options
  position: "top-right",
});
Vue.config.productionTip = false;
library.add(faHatWizard, faCartShopping, faTrashCan, faMoneyCheck, faEye,faCreditCard),
  Vue.component("v-select", vSelect);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(library);
Vue.use(FontAwesomeIcon);
// Vue.use(VM2, {
//   componentName: "ModalVue",
// });
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
