<template>
  <section id='faq' class="faq-section" style="margin:-10px">
    <div class="container">
      <h2>
        {{ $t("section6.titre1") }}
      </h2>
      <VueFaqAccordion
      class="d-flex align-items-center justify-content-center"
      :items="myItems"
      activeColor="#337fa8"
      borderColor="#337fa8"
      />
    </div>
  </section>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";
export default {
  components: {
    VueFaqAccordion,
  },
  computed: {
    myItems() {
      return [
        {
          title: this.$t("section6.card1titre1"),
          value: this.$t("section6.card1text1"),
        },
        {
          title: this.$t("section6.card2titre2"),
          value: this.$t("section6.card2text2"),
        },
        {
          title: this.$t("section6.card3titre3"),
          value: this.$t("section6.card3text3"),
        },
        {
          title: this.$t("section6.card4titre4"),
          value: this.$t("section6.card4text4"),
        },
        {
          title: this.$t("section6.card5titre5"),
          value:
            this.$t("section6.card5text4part1") + " "+
            this.$t("section6.card5text4part2"),
        },
        {
          title: this.$t("section6.card6titre6"),
          value: this.$t("section6.card6text6"),
        },
      ];
    },
  },
};
</script>
<style scoped>
.TitreFoir {
  position: relative;
  font-family: "Arial";
  font-size: 50px;
  line-height: 100px;
  text-align: center;
}

.imgFAQ {
  width: 11%;
  margin-left: 16%;
  margin-top: 1%;
}

.contentFAQ {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>
