export default {
  topnet: {
    authIsRequired: false,
    fields: [
      {
        type: "input",
        label: "Identifiant ",
        required: true,
        vModel: "id",

        rules: (v) =>
          !(v && !/^[0-9]{8}$/.test(v)) ||
          !(v && !/^[A-Z]{1}[0-9]{6}$/.test(v)) ||
          "Identifiant est invalide",
      },
      {
        type: "input",
        label: "Code Client / Libellé Contrat / Numéro de la Facture",
        vModel: "topnetId",
        required: true,
      },
      {
        type: "input",
        label: "Votre Matricule Fiscal",
        vModel: "clientMF",
        search: "business",
        //validators
        required: true,
        rules: (v) =>
          !(v && !/^\d{7}[^IOU\W\d][APBFN][MPCNE]\d{3}/.test(v)) ||
          "Matricule Fiscal est invalide",
      },
      {
        type: "input",
        label:
          "Code Client / Libellé Contrat / Numéro de la Facture/ Reference TTN",
        vModel: "topnetId",
        search: "business",
        required: true,
      },
    ],
  },
  entreprise: {
    authIsRequired: false,
    helpContent: "test",
    fields: [
      {
        type: "input",
        label: "Votre Matricule Fiscal",
        vModel: "clientMF",
        rules: (v) =>
          /^\d{7}[^IOU\W\d][APBFN][MPCNE]\d{3}$/.test(v) ||
          "Matricule fiscal est invalide",
      },
      {
        type: "input",
        label: "Matricule Fiscal Fournisseur",
        vModel: "billerMF",
        rules: (v) =>
          /^$|^\d{7}[^IOU\W\d][APBFN][MPCNE]\d{3}$/.test(v) ||
          "Matricule fiscal est invalide",
      },
      {
        type: "input",
        label: "Matricule Fiscal Client ",
        vModel: "client",
        search: "byOne",
        required: true,
      },
      {
        type: "input",
        label: "Référence Facture",
        vModel: "invoiceRef",
      },
      {
        type: "input",
        label: "Référence TTN",
        vModel: "ttnReference",
      },
      {
        type: "input",
        label: "Matricule Fiscal Client ",
        vModel: "client",
        search: "byOne",
        required: true,
      },
      {
        type: "input",
        label: "Référence TTN",
        vModel: "reference",
        search: "byOne",
      },
    ],
  },
  particulier: {
    authIsRequired: false,
    helpContent: "testParticulier",
    fields: [
      {
        type: "option",
        enum: ["CIN", "Num passport", "Carte de Séjour"],
        label: "Votre Identité",
        initValue: "CIN",
        vModel: "idType",
      },
      {
        type: "input",
        label: "Identifiant ",
        vModel: "id",
        rules: (v) => /^[0-9]{8}$/.test(v) || "id est invalide",
      },
      {
        type: "input",
        label: "Matricule Fiscal Fournisseur ",
        vModel: "billerMF",
        rules: (v) =>
          /^\d{7}[^IOU\W\d][APBFN][MPCNE]\d{3}$/.test(v) ||
          "Matricule fiscal est invalide",
      },
      {
        type: "input",
        label: "Référence Facture ",
        vModel: "invoiceRef",
      },
    ],
  },
  gouvernement: {
    authIsRequired: false,
    fields: [
      {
        type: "input",
        label: "Votre Matricul Fiscal",
        vModel: "taxRegNumberClient",
      },
    ],
  },
  steg: {
    authIsRequired: false,
    fields: [
      //TAPER CIN
      // {
      //   type: "input",
      //   label: "CIN ",
      //   vModel: "clientCin",
      //   required: true,

      //   rules: (v) => /^[0-9]{8}$/.test(v) || "Cin est invalide",
      // },

      {
        type: "input",
        label: "Veuillez saisie la référence de l’abonnement STEG",
        vModel: "invoiceRef",

        rules: (v) => /^[0-9]{9}$/.test(v) || "Référence est invalide",
        required: true,
      },
    ],
  },
  sonede: {
     authIsRequired: false,
    fields: [
      {
        type: "input",
        label: "Veuillez saisir l’une des critères SONEDE : Code client / Police / Référence facture",
        vModel: "sonedId",
        required: true,
      },
      
      // {
      //   type: "input",
      //   label: "Code client",
      //   vModel: "clientCode",

      //   rules: (v) => /[0-9]{3}1[A-Z]{1}[0-9]{7}/.test(v) || "code  est invalide",
      //   required: false,
      // },
      // {
      //   type: "input",
      //   label: "Référence",
      //   vModel: "invoiceRef",

      //   rules: (v) => /^[0-9]{9}$/.test(v) || "Référence est invalide",
      //   required: false,
      // },
    ],
  },
  ttn: {
    authIsRequired: true,
    fields: [
      {
        type: "input",
        label: "Matricule Fiscale Client",
        vModel: "taxRegNumberClient",
        preFill: "taxRegNum",
      },
      {
        type: "input",
        label: "Réference TTN",
        vModel: "ttn",
      },
    ],
  },
};
