<template>
    <section id="our-services" class="position-relative">
      <div  :class="[($i18n.locale === 'fr' || $i18n.locale === 'en') ?'main-our-services':'main-our-services-ar']" style="height:470px">
        <h2 style="width:70%">
        {{ $t("section3.titre") }}
      </h2>
      </div>
        <div class="pay-step" 
        style="
        transform: translate(-50%, -50%);
        left: 50%;
        gap:15px;
        ">
          <div 
            class="d-flex flex-column align-items-center pay-step-box-2 p-1 pt-3">
            <h5>{{ $t("section3.etapef") }}</h5>
            <p style="font-size: 24px;
            color: #576269;
            font-weight: 700;text-align: center;" class="pay-p">{{ $t("section3.etape1") }}</p>
          </div>
          <div 
            class="d-flex flex-column align-items-center pay-step-box-2 p-1 pt-3"
            >
            <h5>{{ $t("section3.etapes") }}</h5>
            <p style="font-size: 24px;
            color: #576269;
            font-weight: 700;" class="pay-p text-center">{{ $t("section3.etape3") }}</p>
         
          </div>
          <div 
          class="d-flex flex-column align-items-center pay-step-box-2 p-1 pt-3"
            >
            <h5>{{ $t("section3.etapet") }}</h5>
            <p style="font-size: 24px;
            color: #576269;
            font-weight: 700;
            text-align: center;" class="pay-p">{{ $t("section3.etape2") }}</p>
          </div>
        </div>
        <div style="height:780px;" class="inherit-height inherit---height">
          <div class="pay-separation">
          </div>
          <div class="d-flex flex-column align-items-center account-btn" style="gap:5px;">
            <button @click="createAccount()" 
              class="btn-turquoise">
            {{ $t("section1.compte") }}
          </button>
          <span class="pay-p">{{ $t("section1.ou") }}</span>
          <button @click="login()" 
              class="btn-turquoise-outline">
            {{ $t("section1.connexion") }}
          </button>
          </div>
          <div style="background-color:#f5f6f8 !important;margin-top:25px">
            <div class="container">
            <div class="row">
              <div class="col-md-6 d-flex align-items-center justify-content-center p-0">
                <img src="@/assets/all_new_images/Screen2.png" style="height:504px"/>
              </div>
              <div class="col-md-6">
                <h3>{{ $t("section3.titreApp") }}</h3>
              <p 
              style="
              font-size: 17px;
              color: #6d7783;
              font-weight: 400;
              text-align: justify;
              "
              class="pay-p"
              >
                {{ $t("section3.p") }} 
              </p>
              <div
            class="d-flex flex-column align-items-end"
            style="gap: 9px;
            margin-top: 32px;"
          >
            <span
              style="
                font-size: 15px;
                letter-spacing: 0px;
                line-height: 19px;
                text-decoration: underline;
                color: #686868;
                font-family: 'Helvetica';
              "
              class="pay-p"
            >
            {{ $t("section3.télécharger_appli") }}
            </span>
            <ul
              class="d-flex align-items-center down-btn"
              style="gap: 10px; margin-top: 15px"
            >
              <li
              title="trés prochainement">
                <a  :href="apple_store" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/footer/logos/download-apple.png" alt="da" class="scale"
                /></a>
              </li>
              <li
              title="trés prochainement">
                <a :href="google_play" target="_blank" rel="noopener noreferrer" >
                  <img src="@/assets/footer/logos/download-google.png" alt="dg" class="scale"
                /></a>
              </li>
            </ul>
          </div>
              </div>
          </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {
  data() {
    return {
      heroBg: require("@/assets/images/bg3.png"),
      loginUrl: process.env.VUE_APP_LOGIN_URL,
      google_play: process.env.VUE_APP_PAYSMART_MOBILE_GOOGLE,
      apple_store: process.env.VUE_APP_PAYSMART_MOBILE_APPLE,
      //loginUrl: "https://staging.fatourti.we-settle.com/express"
    };
  },

  methods: {
    createAccount() {
      window.open(this.loginUrl + this.$i18n.locale + "/register");
    },
    login() {
      window.open(this.loginUrl + this.$i18n.locale + "/login");
    },
  },
};
</script>
<style scoped>
#group {
  text-align: justify;
}

#group div {
  display: inline-block;
}

#group:after {
  content: "";
  display: inline-block;
  width: 100%;
}
@media (min-width: 600px) and (max-width: 2000px) {
  .row-col {
    margin-left: 50px;
  }
}

.icone {
  width: 20% !important;
  margin: 0 auto !important;
}
.titre {
  text-align: center;
  margin-top: 20px;
}
.feature-item1 {
  border: 1px solid #1999c2;
  width: 85%;
  padding: 15px;
  background-color: white;
  border-radius: 17px;
  margin: 10px;
  height: 90%;
}
.desc {
  color: #57617b;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 0;
}
.col-sm {
  margin: 2%;
}
.feature-item {
  width: 80%;
  height: 100%;
  border: 1px solid #2222a9;
  width: 68%;
  height: 84%;
}
.feature-icon {
  width: 28%;
  margin-left: 73px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .iconerep {
    width: 30% !important;
    margin: 0 auto !important;
    border: 1px solid #ff00a7;
  }
  .titre {
    text-align: center;
    margin-top: 20px;
  }
  .feature-item1resp {
    width: 79%;
    padding: 15px;
    background-color: white;
    border-radius: 17px;
    margin: 10px;
    height: 90%;
  }
  .title-resp {
    font-size: 14px !important;
    margin: 0 !important;
  }

  /* .feature-item{
        background-color: #ffffff;
    border-radius: 30px;
    text-align: center;
    padding-top: 50px;
    border: 1px solid red;
    padding-top: 50px; 
     padding-bottom: 0px; 
     padding-left: 0px; 
     padding-right: 0px; */

  /* padding-bottom: 45px; 
   padding-left: 40px; 
   padding-right: 40px; */

  .feature-content {
    border: 1px solid #ff00a7;
  }
}
@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  .row-col-resp {
    margin-right: 0px !important;
  }

  .iconerep {
    width: 26% !important;
    margin: 0 auto !important;
  }
  .titre {
    text-align: center;
    margin-top: 20px;
  }
  .feature-item1resp {
    width: 100%;
    padding: 15px;
    background-color: white;
    border-radius: 17px;
    margin: 10px;
    height: 85%;
  }
  .title-resp {
    font-size: 9px !important;
    margin: 0 !important;
  }
  .feature-icon {
    width: 26%;
    /* HEIGHT: 24%; */
    margin-left: 50px;

    margin-top: -29px;
    margin-bottom: 0px;
  }
  .feature-item {
    background-color: #ffffff;
    border-radius: 30px;
    text-align: center;
    padding-top: 50px;
    border: 1px solid rgb(51, 46, 46);
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    height: 85%;

    /* padding-bottom: 45px; 
   padding-left: 40px; 
   padding-right: 40px; */
  }
  .feature-content {
    border: 1px solid #ff00a7;
    width: 100%;
    height: 50%;
  }
}
@media screen and (max-width: 480px) {
  .col-3 {
    width: 100%;
    margin: 5px 0;
    display: block;
  }
}
</style>
