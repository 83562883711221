<template>
  <!-- Begin Footer Area -->
  <footer style="background-color:#337fa8 !important">
    <div class="container">
      <div class="row">
        <div class="col d-flex flex-column" style="gap: 25px">
          <a>
            <img src="@/assets/header/logos/mognetique.png" alt="Logo" />
          </a>
          <ul
            class="d-flex flex-column"
            style="
              padding: 0px !important;
              font-size: 16px;
              letter-spacing: 1px;
              line-height: 36px;
              color: #ffffff;
              font-weight: 600;
              font-family: 'Source Sans Pro';
            "
          >
            <li>
              <a
                href="#discover-paysmart"
                class="text-decoration-none text-white"
                >{{ $t("nav.Paysmart") }}</a
              >
            </li>
            <li>
              <a href="#our-services" class="text-decoration-none text-white">{{
                $t("nav.service")
              }}</a>
            </li>
            <li>
              <a href="#reclamation" class="text-decoration-none text-white">{{
                $t("nav.reclamation")
              }}</a>
            </li>
            <li>
              <a href="#partners" class="text-decoration-none text-white">{{
                $t("nav.reference")
              }}</a>
            </li>
          </ul>
          <ul
            class="d-flex align-items-center"
            style="gap: 20px; padding: 0px !important"
          >
            <li>
              <a href="#">
                <img src="@/assets/footer/logos/instagram.png" alt="insta"
              /></a>
            </li>
            <li>
              <a href="#">
                <img src="@/assets/footer/logos/facebook.png" alt="fb"
              /></a>
            </li>
            <li>
              <a href="#">
                <img src="@/assets/footer/logos/twitter.png" alt="twit"
              /></a>
            </li>
            <li>
              <a href="#">
                <img src="@/assets/footer/logos/linkedin.png" alt="link"
              /></a>
            </li>
          </ul>
        </div>
        <div
          class="col d-flex flex-column align-items-end"
          style="gap: 35px"
        >
          <img
            src="@/assets/header/logos/paysmart.png"
            alt="paysmart"
            width="180"
            height="49"
          />
          <!-- <hr style="width: 13px; height: 2px; background-color: #d8d8d8" /> -->
          <div
            class="d-flex align-items-center justify-content-end text-white"
            style="gap: 15px"
          >
            <span
              style="
                display: flex;
                flex-direction: column;
                font-size: 12px;
                letter-spacing: 1px;
                color: #ffffff;
                font-family: 'Helvetica';
                text-align: right;
              "
            >
              <span>{{ $t("republique1") }}</span>
              <span>{{ $t("republique2") }}</span>
            </span>
            <img src="@/assets/header/logos/TN.png" height="28" alt="TN" />
          </div>
          <div
            class="d-flex flex-column align-items-end"
            style="gap: 0px; margin-top: 20px"
          >
            <span
              style="
                font-size: 14px;
                color: #ffffff;
                font-weight: 300;
                font-family: 'Source Sans Pro';
              "
            >
            {{ $t("footer.appli_disponible") }}
            </span>
            <ul
              class="d-flex align-items-center"
              style="gap: 10px; margin-top: 23px"
            >
              <li>
                <a :href="apple_store" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/footer/logos/download-apple.png" alt="da"
                /></a>
              </li>
              <li>
                <a :href="google_play" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/footer/logos/download-google.png" alt="dg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="
          font-size: 12px;
          letter-spacing: 0px;
          color: #ffffff;
          font-weight: 300;
          font-family: 'Source Sans Pro';
          padding-bottom: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          cursor:pointer;
        ">
      <div class="cgu-div">
      <span
        class="text-white fw-bold fs-6 text-decoration-underline"
        @click="openCGU"
      >
       {{ $t("cguTitre") }}
      </span>
      <v-dialog v-model="dialogCompose" max-width="600">
        <form
          class="custom-reclamation-form position-relative" 
          style="
            overflow-y: hidden !important;
            background-color: #fff !important;
            padding: 25px;
            margin: 0 !important;
          "
        >
          <img
            @click="closeCGU"
            class="close-modal-icon scale"
            src="@/assets/header/logos/plus-copy.png"
          />
          <CGU></CGU>
        </form>
      </v-dialog>
    </div>
      </div>
      <div
        style="
          font-size: 12px;
          letter-spacing: 0px;
          color: #ffffff;
          font-weight: 300;
          font-family: 'Source Sans Pro';
          border-top: 1px solid #fff;
          padding-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
        "
        class="we-size"
      >
        &copy;
        {{ new Date().getFullYear() }} 
        <a style="margin-left:6px"
          class="text-decoration-none text-white"
        >
          {{ $t("footer.paysmart") }}
          </a>
          . {{ $t("footer.droit") }} 
          <a
          href="https://we-settle.com/"
          rel="noopener"
          target="_blank"
          class="text-decoration-none we-logo"
        >
        <img class="partner we-logo " :class="[$i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'ms-4'
                    : 'me-4']" src="@/assets/all_new_images/wesettle2.png"/>
      </a>
      </div>
    </div>
  </footer>
</template>

<script>
import CGU from '../components/CGU.vue'
export default {
  components: {
  CGU
  },
  data() {
    return {
      google_play: process.env.VUE_APP_PAYSMART_MOBILE_GOOGLE,
      apple_store: process.env.VUE_APP_PAYSMART_MOBILE_APPLE,
      footerBg: "@/assets/images/footer/bg/3-1-1920x801.png",
      dialogCompose:false,
      socials: [
        {
          link: "https://www.facebook.com",
          iconName: "icofont-facebook",
        },
        {
          link: "https://www.skype.com",
          iconName: "icofont-skype",
        },
        {
          link: "https://www.twitter.com",
          iconName: "icofont-twitter",
        },
      ],
    };
  },
  methods:{
    openCGU() {
      this.dialogCompose = true;
    },
    closeCGU() {
      this.dialogCompose = false;
    },
  }
};
</script>
<style scoped>
.footer-upper-top {
  justify-content: left;
  margin-left: 10%;
}
.footer-desc {
  max-width: 100% !important;
}
.footer-bottom-item {
  justify-content: center !important;
}
.cgu-link:hover{
color:#337fa8 !important;
font-weight: bold;
}
.we-size{
    font-size: 1.25rem !important;
  }
  .we-logo{
    width:110px !important
  }
@media screen and (max-width:1000px) {
  .we-logo{
    width:60px !important
  }
  .we-size{
    font-size: 12px !important;
  }
}
</style>
