import Vue from "vue";
import Vuex from "vuex";
import card from "./cart";
import fields from "../data/fields.js";
import apiUrl from "../data/apiUrl.js";
import { fetchApi } from "../utils";
import Swal from "sweetalert2";

// import topnet from "";
 import i18n from '../i18n'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categories: [
      {
        name: "Internet et Téléphonie",
        params: "internet",
        categories: [
          {
            name: "Topnet",
            variable: 600,
            icon: "600.png",
            params: "/topnet",
            available: true,
          },

          {
            name: "Tunisie Telecom",
            icon: "Tunisie-Telecom.png",
            params: "/telecom",
            available: true,
          },
          {
            name: "Oreedoo",
            icon: "oreedoo.png",
            params: "/oreedoo",
            available: true,
          },
          {
            name: "Orange",
            icon: "orange.png",
            params: "/orange",
            available: true,
          },
        ],
      },
      {
        name: "Energie et Eau",
        params: "energie",
        categories: [
          {
            name: "Steg",
            variable: 701,
            icon: "701.png",
            params: "/steg",
            available: true,
          },
          {
            name: "Sonede",
            variable: 502,
            icon: "502.png",
            params: "/sonede",
            available: true,
          },
        ],
      },
      {
        name: "Transport",
        params: "transport",
        categories: [
          {
            name: "Autoroute",
            icon: "autoroute.png",
            params: "/autoroute",
            available: true,
          },
          {
            name: "Abonnement",
            icon: "abonnement.png",
            params: "/abonnement",
            available: true,
          },
        ],
      },
      {
        name: "Education et Enseignement",
        params: "education",
        categories: [
          {
            name: "Inscription Secondaire",
            icon: "ecole.png",
            params: "/inscrisecond",
            available: true,
          },
          {
            name: "Inscription Universitaire",
            icon: "université.png",
            params: "/inscriunv",
            available: true,
          },
        ],
      },
      {
        name: "E-Gouvernement",

        params: "gouvernement",
        categories: [
          {
            name: "visite technique",
            icon: "visite.png",
            params: "/visitetechnique",
            available: true,
          },
          {
            name: "Amendes",
            icon: "amende.png",
            params: "/amende",
            available: true,
          },
          {
            name: "CNSS",
            icon: "cnss.png",
            params: "/cnss",
            available: true,
          },
          {
            name: "Timbres fiscaux",
            icon: "timbre.png",
            params: "/timbre",
            available: true,
          },
        ],
      },
      {
        name: "Business",
        params: "business",
        categories: [
          {
            name: "Facture Electronique",
            icon: "TTN.png",
            params: "/ttn",
            available: true,
          },

          {
            name: "Particulier",
            icon: "B2C.png",
            params: "/particulier",
            available: true,
          },
          {
            name: "Entreprise",
            icon: "B2B.png",
            params: "/entreprise",
            available: true,
          },
        ],
      },
    ],
    fields,
    busy: false,
    apiUrl,
    invoices: [],
    searchbills: [],
    recharges: [],
    recharge:{},
    rechargePayments:[],
    searchrecharges: [],
    services:[],
    service:{},

    invoice: {
      acceptedPaymentModes: [],
    },
    paymentItems: [],
    paymentId: "",
    paymentRechargeId: "",
    subCategory: "",
    initPayment: false,
    invoiceIdToPay: "",
    error: "",
    captchaResponse: "",
  },
  getters: {
    isBusy(state) {
      return state.busy;
    },
    // cards: (state) => state.cards,
    getCategory: (state) => (params) => {
      return state.categories.filter((el) => el.params === params);
    },
    getError: (state) => state.error,
    getSubCategory:
      (state) =>
      ({ category, subCategory }) => {
        return state.categories
          .filter((el) => el.params === category)[0]
          ?.categories?.filter((el) => el.params === "/" + subCategory);
      },
    getFields(state) {
      return state.fields[state.subCategory] || {};
    },
    searchbills(state) {
      return state.searchbills;
    },
    searchrecharges(state) {
      return state.searchrecharges;
    },
    invoice(state) {
      return state.invoice;
    },
    invoices(state) {
      return state.invoices;
    },
    service(state) {
      return state.service;
    },
    services(state) {
      return state.services;
    },
    recharge(state) {

      return state.recharge;
    },
    recharges(state) {
      return state.recharges;
    },
    rechargePayments(state) {
      return state.rechargePayments;
    },
    paymentRechargeId(state) {
      return state.paymentRechargeId;
    },

    paymentMethod(state) {
      return state.invoice?.acceptedPaymentModes;
    },
  },
  mutations: {
    setBusy(state, busy) {
      state.busy = busy;
    },
    setInvoices(state, data) {
      console.log("zssszzz");

      state.invoices = data;
    },
    setRecharges(state, data) {
      console.log("zssszzz");
 
      state.recharges = data;
    },
    setService(state, data) {
      console.log("zssszzz",data);
 
      state.service = data;
      console.log("zssszzz",state.service);

    },
    setServices(state, data) {
      console.log("zssszzz",data);
 
      state.services = data;
      console.log("zssszzz",data);


    },
    setRechargePayments(state, data) {
      console.log('passssssssssssssss', data)
      state.rechargePayments = data;
    },
    setInvoice(state, data) {
      console.log("zssszzz");

      state.invoice = data;
    },
    setRecharge(state, data) {
      console.log('rechargeeeeeeeeeeeeeeeee', data)
      state.recharge = data;
    },
    setPaymentRechargeId(state, data) {
      state.paymentRechargeId = data;
    },
    setSearchBills(state, value) {
      state.searchbills.push(value);
    },
    setSearchRecharges(state, value) {
      state.searchrecharges.push(value);
    },
    InitSearchBills(state) {
      state.searchbills = [];
    },
    InitSearchRecharges(state) {
      state.searchrecharges = [];
    },
    updateInvoices(state, invoice) {
      const index = state.invoices.findIndex((el) => el.key === invoice.key);
      if (index < 0) return;
      state.invoices[index].isNew = !state.invoices[index].isNew;
    },
    getInvoiceBykey(state, key) {
      if (key) {
        state.invoice = state.card.cards.find((el) => el.key == key);
      }
    },
    getInvoiceById(state, id) {
      if (id) {
        state.invoice = state.card.cards.find((el) => el.id == id);
      }
    },
    setRequestPaymentId(state, data) {
      state.paymentId = data;
    },
    setSubCategory(state, data) {
      state.subCategory = data;
    },
    initPayment(state, data) {
      state.initPayment = data;
    },
    invoiceIdToPay(state, data) {
      state.invoiceIdToPay = data;
    },
    setError(state, data) {
      state.error = data;
    },
    setCaptchaResponse(state, data) {
      state.captchaResponse = data;
    },
  },
  actions: {
    responseCaptcha({ commit }, response) {
      commit("setCaptchaResponse", response);
    },
    updateInvoices({ commit }, invoice) {
      commit("updateInvoices", invoice);
    },
    setSearchBills({ commit }, value) {
      commit("setSearchBills", value);
    },
    InitSearchBills({ commit }) {
      commit("InitSearchBills");
    },
    setSubCategory({ commit }, payload) {
      commit("setSubCategory", payload);
    },
    initPayment({ commit }, payload) {
      commit("initPayment", payload);
    },
    setInvoices({ commit }, payload) {
      commit("setInvoices", payload);
    },
    setInvoice({ commit }, payload) {
      commit("setInvoice", payload);
    },
    setService({ commit }, payload) {
      commit("setService", payload);
    },
    async fetchInvoices({ commit, dispatch }, { subCategory, query, secaptcha }) {
      console.log("subCategory", subCategory);
      console.log("query", query);
      let url = "";
      try {
        url = subCategory;
      } catch {
        url = apiUrl[subCategory];
      }
      console.log(url);
const queryData = (url == '701') ? {objectReference : query.invoiceRef} : query
      console.log(process.env.VUE_APP_EXPRESS_APP_URL);
      return await fetchApi(
        "GET",
        "/invoices/" + url,
        { ...queryData, nocaptcha: false , captcha:secaptcha },
        commit
      ).then(({ done, data, message }) => {
        if (done) {
          if (data.invoices.length === 0) {
            return getSearhError("empty");
          }
          const invoices = formatInvoices(data.invoices);
          var inv =
            localStorage.getItem("invoices") !== null
              ? localStorage.getItem("invoices")
              : {};
          if (Object.keys(inv).length > 0) {
            inv = JSON.parse(inv);
            invoices.forEach((element) => {
              if (inv.filter((el) => el.id === element.id) > -1) {
                inv = [...inv, element];
              }
            });
            localStorage.setItem("invoices", JSON.stringify(inv));
            // dispatch("addItemToCards", inv);
            commit("setInvoices", inv);
          } 
          else {
            localStorage.setItem("invoices", JSON.stringify(invoices));
            dispatch("addItemToCards", invoices);
            commit("setInvoices", invoices);
          }

          return null;
        } else {
          return getSearhError(message);
        }
      });
    },
    async fetchServices({ commit }, { subCategory, query ,secaptcha }) {
      console.log("subCategory", subCategory);
      console.log("query", query);
      let url = "";
      try {
        url = subCategory;
      } catch {
        url = apiUrl[subCategory];
      }
      console.log(url);
      console.log(process.env.VUE_APP_EXPRESS_APP_URL);
      return await fetchApi(
        "GET",
        "/services/" + url,
        { ...query, nocaptcha: false , captcha:secaptcha},
        commit
      ).then(({ done, data, message }) => {
        if (done) {
          if (data.objects.length === 0) {
            return getSearhError("empty");
          }
          // const invoices = formatInvoices(data.invoices);
          const services = data.objects
          var inv =
            localStorage.getItem("services") !== null
              ? localStorage.getItem("services")
              : {};
          if (Object.keys(inv).length > 0) {
            inv = JSON.parse(inv);
            services.forEach((element) => {
              if (inv.filter((el) => el.id === element.id) > -1) {
                inv = [...inv, element];
              }
            });
            localStorage.setItem("services", JSON.stringify(inv));
            // dispatch("addItemToCards", inv);
            commit("setServices", inv);
          } 
          else {
            localStorage.setItem("services", JSON.stringify(services));
            // dispatch("addItemToCards", services);
            commit("setServices", services);
          }

          return null;
        } else {
          return getSearhError(message);
        }
      });
    },
    async fetchRechargeByRef({ commit, dispatch}, { biller, RechargeRef }) {
      return await fetchApi(
        "GET",
        "/extract/recharges/refresh/" + biller,
        {objectReference:RechargeRef, nocaptcha: true },
        commit
      ).then(async({ done, data, message }) => {
        if (done) {

          if (data?.recharges?.length === 0) {
            return getSearhError("empty");
          }
          // var ch =
          // localStorage.getItem("recharges") !== null
          //   ? localStorage.getItem("recharges")
          //   : {};
          let recharges = JSON.parse(localStorage.getItem("recharges"));
          let rechargeWithPayId = recharges?.map(e=>{
             if(e.id === data?.recharges[0]?.id){
              e.subType = data?.recharges[0]?.subType, 
              e.rechargeAmount = data?.recharges[0]?.rechargeAmount
              e.amountToRecharge = e?.amountToRecharge ? e?.amountToRecharge :10000
              e.paymentStatus =  data?.recharges[0].paymentStatus
            }
             console.log('eeeeeeeeeeeeeeeeeee12345', e.paymentStatus)
             console.log('eeeeeeeeeeeeeeeeeee12345', data?.recharges[0].paymentStatus)

            return e
          });
          localStorage.setItem("recharges", JSON.stringify(rechargeWithPayId));
          await  commit("setRecharges", rechargeWithPayId);
          await  dispatch("getRecharge", rechargeWithPayId.find(e=>e.id===data?.recharges[0]?.id));
            // localStorage.setItem("recharges", JSON.stringify(rechargeWithPayId));
            // commit("setRecharges", rechargeWithPayId);
            // dispatch("getRecharge", rechargeWithPayId[0]);
          // if (Object.keys(ch).length > 0) {
          //   ch = JSON.parse(ch);
          //   rechargeWithPayId.forEach((element) => {
          //     if (ch.filter((el) => el.id === element.id) > -1) {
          //       ch.subType=data?.recharges[0]?.subType, 
          //       ch.rechargeAmount = data?.recharges[0]?.rechargeAmount
          //       ch = [...ch, element];
          //     }
          //   })
          //   localStorage.setItem("recharges", JSON.stringify(ch));
          //   commit("setRecharges", rechargeWithPayId);
          //   dispatch("getRecharge", rechargeWithPayId[0]);
          // } else {
          //   localStorage.setItem("recharges", JSON.stringify(rechargeWithPayId));
          //   commit("setRecharges", rechargeWithPayId);
          //   dispatch("getRecharge", rechargeWithPayId[0]);
          // }
          return done;
        } else {
console.log(message,"res1111xx")
           getSearhError(message);
          //  Vue.$toast.error(i18n.t("prbServeurSta"), {
          //   canTimeout: true,
          //   duration: 3000,
          // });
          return done;

          

        }
      });
    },
    async fetchRecharges({ commit}, { subCategory, query , secaptcha }) {
      let url;
      try {
        url = subCategory;
      } catch {
        url = apiUrl[subCategory];
      }
      if ("RechargeRef" in query) {
        query.objectReference = query.RechargeRef;
        delete query.RechargeRef;
      }


      return await fetchApi(
        "GET",
        "/extract/recharges/" + url,
        { ...query, nocaptcha: false , captcha:secaptcha },
        commit
      ).then(({ done, data, message }) => {
        if (done) {

          if (data.recharges.length === 0) {
            return getSearhError("empty");
          }
          var ch =
          localStorage.getItem("recharges") !== null
            ? localStorage.getItem("recharges")
            : {};
          let recharges = data?.recharges
          let rechargeWithPayId = recharges?.map(e=>{
               return {
              ...e, 
              paymentId:e?.paymentId ? e?.paymentId : e?.id, 
              lastRechargeAmount:e?.lastRechargeAmount ? e?.lastRechargeAmount : 0, 
              lastRechargeDate:e?.lastRechargeDate ? e?.lastRechargeDate : '',
              isRecharged:e?.isRecharged ? e?.isRecharged : false,
              lastRechargeMethod:(e?.paymentMethod ? e?.paymentMethod: e?.methode)?.toLowerCase() === 'w' ? 'Wallet': (e?.paymentMethod || e?.methode) ?? '',
              lastRechargeTransactionRef:e?.transactionReference ? e?.transactionReference : '',
              paidAmount:e?.ttcAmount ? e?.ttcAmount : 0,
              amountToRecharge : e?.amountToRecharge ? e?.amountToRecharge :0
            }
          
          });

          if (Object.keys(ch).length > 0) {
            ch = JSON.parse(ch);
            rechargeWithPayId.forEach((element) => {
              if (ch.filter((el) => el.id === element.id) > -1) {
                ch = [...ch, element];
              }
            })
            localStorage.setItem("recharges", JSON.stringify(ch));
            commit("setRecharges", ch);
          } else {
            localStorage.setItem("recharges", JSON.stringify(rechargeWithPayId));
            commit("setRecharges", rechargeWithPayId);
          }
          return null;
        } else {
          return getSearhError(message);
        }
      });
    },
    async getRecharge({ commit }, recharge) {
      commit("setRecharge",  recharge);
      commit("setPaymentRechargeId", recharge?.paymentId ? recharge?.paymentId : recharge?.id)
    },
    async getRechargeAmount({ commit, dispatch }, body) {
      return await fetchApi(
        "POST",
        "/order/recharges/",
        body?.obj,
        commit
      ).then(({ done, data, message }) => {
        if (done) {
          let recharges = localStorage.getItem("recharges") ?
           JSON.parse(localStorage.getItem("recharges")) : []
           let newRecharges=recharges.map(e=>{
              if(e?.id === body?.recharge?.id){
                e.paymentId = data?.paymentId ,
                e.billerRib = data?.billerRib,
                e.amountToPay = data?.amountToPay,
                e.amountToRecharge = body?.obj?.amount
              }
              return e
           })
          localStorage.setItem("recharges", JSON.stringify(newRecharges));
          dispatch("getRecharge", newRecharges?.find(e=>e.id === body?.recharge?.id));
          return done
        } else {
           getSearhError(message);
           Vue.$toast.error(i18n.t("prbServeurSta"), {
            canTimeout: true,
            duration: 3000,
          });
          return done
        }
      });
    },
    async verifyRecharge({ state, commit}, id){
      return await fetchApi(
        "GET",
        "/verify/recharges/"+id,
        null,
        commit
      ).then(({ done, verified , payment}) => {
        if (done) {
          if (verified && payment?.status.toLowerCase()==='paid') {
          let recharges = JSON.parse(localStorage.getItem("recharges"));
          let newRecharges = recharges.map(e => {
            console.log('rechargex',e.paymentStatus,state.recharge.paymentStatus)
            if(e.id === state.recharge.id){
              e.paymentId=payment?.id
              e.rechargeAmount = payment?.amount
              e.lastRechargeAmount=payment?.paymentAmount
              e.lastRechargeDate=payment?.paymentDate
              e.lastRechargeMethod=(payment?.paymentMethod ? payment?.paymentMethod: payment?.methode)?.toLowerCase() === 'w' ? 'Wallet': (payment?.paymentMethod || payment?.methode) ?? ''
              e.lastRechargeTransactionRef=payment?.transactionReference
              e.paidAmount = payment?.ttcAmount
              e.isRecharged = true
            }
            return e
          })
          localStorage.setItem("recharges", JSON.stringify(newRecharges));
            commit("setRecharges", newRecharges);
            commit("setRecharge", newRecharges?.find(e=>e.id === state?.recharge?.id));
            return true
        }
        else {
            Vue.$toast.error(i18n.t("errPay"), {
              canTimeout: true,
              duration : 5000
          })
          return false
          }
        }else{
          Vue.$toast.error(i18n.t("errPay"), {
            canTimeout: true,
            duration : 5000
        })

          return false
        }
      }).catch(err=>console.log(err));
    },
    async verifyRechargeWithoutMsg({ state, commit}, id){
      return await fetchApi(
        "GET",
        "/verify/recharges/"+id,
        null,
        commit
      ).then(({ done, verified , payment}) => {
        if (done) {
          if (verified && payment?.status.toLowerCase()==='paid') {
          let recharges = JSON.parse(localStorage.getItem("recharges"));
          let newRecharges = recharges.map(e => {
            console.log('rechargex1',e.paymentStatus,state.recharge.paymentStatus)

            if(e.id === state?.recharge?.id){
              e.paymentId = payment?.id,
              e.rechargeAmount = payment?.amount
              e.lastRechargeAmount=payment?.paymentAmount
              e.lastRechargeDate=payment?.paymentDate
              e.lastRechargeMethod=(payment?.paymentMethod ? payment?.paymentMethod: payment?.methode)?.toLowerCase() === 'w' ? 'Wallet': (payment?.paymentMethod || payment?.methode) ?? ''
              e.lastRechargeTransactionRef=payment?.transactionReference
              e.paidAmount = payment?.ttcAmount
              e.isRecharged = true
            }
            return e
          })
          localStorage.setItem("recharges", JSON.stringify(newRecharges));
            commit("setRecharges", newRecharges);
            commit("setRecharge", newRecharges?.find(e=>e.id === state.recharge.id));
            return true
        }
        else {
          return false
          }
        }else{
          return false
        }
      }).catch(err=>console.log(err));
    },
    async confirmCharging({ commit, state, dispatch }, obj ) {
      return await fetchApi("GET", "/recharges/payment/",obj,commit
      )
      .then(async(response) => {
      const {url,paymentId, done} = response
      commit("setPaymentRechargeId", paymentId)
      let recharges = JSON.parse(localStorage.getItem("recharges"));
      let newRecharges = recharges.map(e => {
        if(e.id === state.recharge.id){
          e.paymentId= paymentId
          e.isRecharged = false
        }
        return e
      })
      localStorage.setItem("recharges", JSON.stringify(newRecharges));
      commit("setRecharges", newRecharges);
      dispatch("getRecharge", newRecharges?.find(e=>e.id === state.recharge.id));
      const urlClickToPay = url
      if(done && urlClickToPay ){
        const referenceWindow =  window.open(
          urlClickToPay,
                  "pay",
                  "popup",
                );
        const timer = setInterval(async () => {
            if (referenceWindow.closed) {
                    clearInterval(timer);
                    await dispatch('verifyRecharge', paymentId);
                    await dispatch('fetchRechargeByRef',{RechargeRef:state.recharge.rechargeRef,biller:"003"})
            }
            }, 500);
      }    
      }
      )
      .catch((error) => {
         console.log('errrrrrrrrrrrrrrrrrrr click2pay', error);
      });
      
    },
    async  refreshOrder({  commit }, obj){
  const url = `/order/services/`;
 return fetchApi(
    "POST",
    url,
    obj,
    commit
  ).then(async (response) => {
    const { done, data } = response;
    console.log("data payment ", data);
    console.log("data payment ", done);
  
  return response
  })
},
async refreshPaymentSchool({ dispatch, commit }, obj) {
  console.log("00000000000000000000000000000000000",obj);





  const response = await  dispatch("refreshOrder",obj)
console.log("data payment response", response);
    if(!response?.done && response?.data?.length > 0) {

          const isPaid = response?.data[0]
          console.log("data payment ",isPaid?.paymentStatus);

          if (isPaid?.paymentStatus === "PAID" || isPaid?.paymentStatus === "HPAID") {
 
            let servicesData = JSON.parse(localStorage.getItem("services"));
            let newServices = servicesData.map(e => {
              if(e.id === obj?.id && isPaid?.paymentStatus === "PAID"){
                e.paymentStatus ="PAID"
                e.paymentsList = isPaid?.paymentsList;
               
              }
              else if (e.id === obj?.id && isPaid?.paymentStatus === "HPAID"){
                e.paymentStatus ="HPAID"
               
              }
              return e
            })
            localStorage.setItem("services", JSON.stringify(newServices));
            commit("setServices", newServices);
            commit("setService", newServices?.find(e=>e.id === obj?.id));
          
            return true

          }
        } 
        else return ""

  //});

 
},
    async confirmPaymentSchool({ dispatch, commit }, obj) {
      console.log("00000000000000000000000000000000000",obj);
   
  
  
      let paymentId = "";
   

      const response = await  dispatch("refreshOrder",obj)
   console.log("data payment response", response);
           if (response.done) {
          console.log("data payment ", response?.data);
           paymentId = response?.data;
           const data = {id:paymentId,method:obj?.method,pan:"*****"};
  
           await fetchApi("GET", "/school/payment/", data, commit)
             .then(async (response) => {
               console.log("hello from my", response);
               
               const urlClickToPay = response?.url;
               if (response?.done && urlClickToPay) {
                 const referenceWindow = window.open(urlClickToPay, "pay", "popup");
                 console.log("test 1");
                 const timer = setInterval(async () => {
                   if (referenceWindow.closed) {
                     clearInterval(timer);
                     console.log("test 2");
                     console.log("test 3");
                     const isPaid = await dispatch("checkPaymentExistService",paymentId);
                     console.log("tttttttttttttttt1", isPaid);
                     if (isPaid) {
                      
                       if (isPaid?.status === "paid") {

                         let servicesData = JSON.parse(localStorage.getItem("services"));
                         let newServices = servicesData.map(e => {
                           if(e.id === obj?.id){
                             const newPayment = {
                               "transactionCode": isPaid?.transactionCode,
                               "transactionStatus": isPaid?.status === "paid" ? "PAID" :"",
                               "billerAuthIds": [isPaid?.billerAuthId],
                               "rejectionReason": "",
                               "transactionReference": isPaid?.transactionReference,
                               "originalAmount": isPaid?.amount,
                               "paymentAmount": isPaid?.amount,
                               "addedAmount": "",
                               "taxAmount": "",
                               "discount": "",
                               "paymentDate": isPaid?.paymentDate,
                               "sourceAccount": isPaid?.sourceAccount,
                               "destinationAccount": isPaid?.destinationAccount,
                               "paymentReceipt": {},
                               "paymentCanal": isPaid?.paymentCanal,
                               "paymentMode": isPaid?.method,
                               "paymentMean": isPaid?.paymentMean
                             };
                             e.paymentStatus ="PAID"
                             e.paymentsList.push(newPayment);
                            
                           }
                           return e
                         })
                         localStorage.setItem("services", JSON.stringify(newServices));
                         commit("setServices", newServices);
                         commit("setService", newServices?.find(e=>e.id === obj?.id));
                         Swal.fire({
                           icon: "success",
                           text: "Service payée avec succès ",
                           confirmButtonColor: "#1db7c2",
                           confirmButtonText: "Fermer",
                         });
                         return true
     
                       }
                    
                     else {
                      // return Swal.fire({
                      //   icon: "error",
                      //   text: i18n.t("errPay"),
                      //   confirmButtonColor: "#1DB7C2",
                      //   confirmButtonText: i18n.t("Fermer"),
                      // });
                      return Vue.$toast.error(i18n.t("errPay"), {
                        canTimeout: true,
                        duration : 5000
                    })
                    }}
                   }
                 }, 500);
               }
                else {
                 return Swal.fire({
                   icon: "error",
                   text: i18n.t("modalDetail.errclickToPay"),
                   confirmButtonColor: "#1DB7C2",
                   confirmButtonText: i18n.t("Fermer"),
                 });
               }
             })
             .catch((error) => {
               if (error) {
                 return Swal.fire({
                   icon: "error",
                   text: i18n.t("modalDetail.errclickToPay"),
                   confirmButtonColor: "#1db7c2",
                   confirmButtonText: i18n.t("Fermer"),
                 });
               }
             });  }
        if(!response?.done && response?.data?.length > 0) {

              const isPaid = response?.data[0]
              console.log("data payment ",isPaid?.paymentStatus);

              if (isPaid?.paymentStatus === "PAID" || isPaid?.paymentStatus === "HPAID") {
     
                let servicesData = JSON.parse(localStorage.getItem("services"));
                let newServices = servicesData.map(e => {
                  if(e.id === obj?.id && isPaid?.paymentStatus === "PAID"){
                    e.paymentStatus ="PAID"
                    e.paymentsList = isPaid?.paymentsList;
                   
                  }
                  else if (e.id === obj?.id && isPaid?.paymentStatus === "HPAID"){
                    e.paymentStatus ="HPAID"
                   
                  }
                  return e
                })
                localStorage.setItem("services", JSON.stringify(newServices));
                commit("setServices", newServices);
                commit("setService", newServices?.find(e=>e.id === obj?.id));
                Swal.fire({
                  icon: "success",
                  text: "Service Deja Payé ",
                  confirmButtonColor: "#1db7c2",
                  confirmButtonText: "Fermer",
                });
                return true

              }
            }    
      //});
  
     
    },
    getInvoiceBykey({ commit }, key) {
      commit("getInvoiceBykey", key);
    },
    getInvoiceById({ commit }, id) {
      commit("getInvoiceById", id);
    },
    setError({ commit }, payload) {
      commit("setError", payload);
    },
    //get id
    async getFromLocalStorage({ commit, state }) {
      try {
        const oldIdsAsString = JSON.parse(
          window.localStorage.getItem("invoiceId")
        );
        var oldIds = [];

        oldIds.push(oldIdsAsString[0].id);
        console.log("oldIds :>> ", oldIds);
        console.log("oldIdsAsString :>> ", oldIdsAsString);

        await fetchApi(
          "GET",
          "/invoices",
          { ids: oldIds, paymentMean: process.env.VUE_APP_CLICKTOPAY },
          commit
        ).then(({ done, data }) => {
          console.log("res :>> err click to pay ", { done, data });
          if (done && data.length > 0) {
            console.log("state.card.cards :>> ", state.card.cards);
            data.map((el) => {
              if (el.invoice) {
                console.log("map.invoice :>> ", el.invoice);

                const invoiceData = JSON.parse(
                  localStorage.getItem("invoices")
                );
                const dataInv = invoiceData.map((inv) => {
                  if (inv.id === el.invoice.id) {
                    console.log("map.isPaid :>> ", el.invoice.id);
                    inv.paymentStatus = el.invoice.paymentStatus;
                  }
                  return inv;
                });
                console.log("new invoices", dataInv);
                localStorage.setItem("invoices", JSON.stringify(dataInv));
                commit("setCards", formatInvoices(dataInv));
              } else {
                console.log("map.oldIdAstring :>> ", oldIdsAsString);
              }
            });
          } else {
            console.log("map.oldId :>> ", oldIdsAsString);
          }
        });

        return oldIds;
      } catch (error) {
        console.log("error :>> ", error);
        return error;
      }
    },

  async  refreshInvoice({ commit }, invoice) {
      var id = [];
      id.push(invoice.id);
      try {
        await fetchApi("GET", "/invoices", { ids: id ,paymentMean: this.Click_To_Pay,invoiceRef:invoice?.invoiceRef , biller: invoice?.biller
        }, commit).then(
          ({ done, data }) => {
            if (done) {
              data.map((el) => {
                if (el.invoice) {
                  console.log("map.invoice :>> ", el.invoice);

                  const invoiceData = JSON.parse(
                    localStorage.getItem("invoices")
                  );
                  const dataInv = invoiceData.map((inv) => {
                    if (inv.id === el.invoice.id ) {
                      console.log("map.isPaid :>> ", el.invoice.id);
                      inv.paymentStatus = el.invoice.paymentStatus;
                      // inv.paymentsList = el.invoice.paymentsList[0].paymentDate
                      el.invoice.paymentsList[0] ?  inv.paymentsList.push(el.invoice.paymentsList[0]):   inv.paymentsList = [];



                    }
                    return inv;
                  });
                  console.log("new invoices", dataInv);
                  localStorage.setItem("invoices", JSON.stringify(dataInv));
                  commit("setInvoices", dataInv);
                } else {
                  console.log("map.oldIdAstring :>> ");
                }
              });
            } else {
              console.log("eeee");
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    async  refreshServicesData({ commit }, service) {

      try {
        await fetchApi("GET", "/refresh/services/", {  paymentMean: this.Click_To_Pay,invoiceRef:service?.objectRef , biller: service?.biller
        }, commit).then(
          ({ done, data }) => {
            if (done) {
              console.log("data.objects", data.objects);

              data.objects.map((el) => {
                console.log("data.objects el", el);
                if (el) {

                  const serviceData = JSON.parse(
                    localStorage.getItem("services")
                  );
                  const dataInv = serviceData.map((inv) => {
                    if (inv.id === el.id ) {
                      console.log("map.isPaid :>> ", el.id);
                      inv.paymentStatus = el.paymentStatus;
                      // inv.paymentsList = el.service.paymentsList[0].paymentDate
                      el.paymentsList[0] ?  inv.paymentsList.push(el.paymentsList[0]):   inv.paymentsList = [];



                    }
                    return inv;
                  });
                  console.log("new services", dataInv);
                  localStorage.setItem("services", JSON.stringify(dataInv));
                  commit("setServices", dataInv);
                  commit("setService", dataInv?.find(e=>e.id === el.id ));

                } else {
                  console.log("map.oldIdAstring :>> ");
                }
              });
            } else {
              console.log("eeee");
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
  async addToPrint({commit}, code){
      console.log('codeeee', code)  
      return fetchApi("GET", "/edp", { code:code }, commit)
      .then((res) => {
        console.log('resssssssss', res)
          return res.solution
      }).catch(err => console.log(err))
      },
  async  printInvoice({ commit }, invoice) {
    let sol
      var id = [];
      id.push(invoice?.id);
          let inv = await fetchApi("GET", "/invoices", { ids: id ,paymentMean: this.Click_To_Pay,invoiceRef:invoice?.invoiceRef , biller: invoice?.biller
          }, commit)
        .then(
          ({ done, data }) => {
            if (done) {
              data.map(async (el) => {
              if (el.invoice) {
                  if(el.invoice.paymentsList.length!==0){
                    let lastPay= el.invoice.paymentsList.filter(e => e.transactionStatus.toLowerCase() === 'paid')
                    el.invoice.payDate = lastPay[0]?.paymentDate
                    el.invoice.sourceAccount = lastPay[0]?.sourceAccount
                    el.invoice.transactionReference = lastPay[0]?.transactionReference
                    el.invoice.dataAmount= lastPay[0]?.paymentAmount  
                    switch (lastPay[0].paymentCanal) {
                      case 'C01':
                        el.invoice.paymentCanal = 'web'
                        break;
                      case 'C02':
                        el.invoice.paymentCanal = 'mobile'
                        break;
                      default:
                        break;
                    }
                    sol = await fetchApi("GET", "/edp", { code:lastPay[0].paymentMean }, commit)
                    .then((res) => {
                      return res.solution
                    }).catch(err => console.log(err))
                    }   
                el.invoice.solutionName = sol.label
                el.invoice.bankName = sol?.fsp?.name
                el.invoice.paymentMode = sol.method
                el.invoice.fspName=sol?.fsp?.displayName
                el.invoice.codeFsp=sol?.fsp?.code
           
              return el.invoice        
              }
              });
            }
            return data
          }
        ).catch(err => console.log(err))
        console.log('from strorrrrrr', inv[0].invoice)
        return inv[0].invoice
    },
    setToLocalStorage({ state }) {
      const cards = state.cards;
      window.localStorage.setItem("cards", cards);
    },
    async addItemsToPayment({ commit, state, dispatch }, id) {
      console.log("ssssssss---qqqqqqqqqqqqqqqqqqqqq----------");
      let invoiceId = id.invoiceId;
      let paymentMean = id.paymentMean;
      console.log("invoceId :>> ", invoiceId);
      console.log("paymentMean :>> ", paymentMean);
      let paymentItemsId = [];
      let amount = 0;
      if (!invoiceId) {
        console.warn("noItd :>> ");
        paymentItemsId = state.card.cards.map((el) => el.id);
        amount = state.card.cards.reduce(
          (a, b) => a + Number(b.invoiceAmount),
          0
        );
      } else {
        console.warn("id :>> ");
        console.log("object :>> ", state.card.cards);
        console.log("invoiceId :>> ", invoiceId);
        paymentItemsId = [invoiceId];
        commit("invoiceIdToPay", invoiceId);
        console.log(state.card.cards);
        amount = JSON.parse(localStorage.getItem("invoices")).find(
          (el) => el.id == invoiceId
        ).invoiceAmount;
      }
      console.log("prixxxxxxxx ", amount);
      // return await fetchApi("POST", "/", { paymentItemsId, amount }, commit)
      //   .then(({ done, data }) => {
      //     if (done) {
      //       console.log("78777778788878966666",data);
      //       commit("setRequestPaymentId", data);
      //       return true;
      //     }
      //     dispatch("addItemToCards", formatInvoices(data));
      //     return false;
      //   })
      //   .catch((err) => console.error(err));
      const dataToResturn = await fetchApi(
        "POST",
        "/",
        {
          paymentItemsId: paymentItemsId,
          paymentMean: paymentMean,
          paymentCanal: "C01",
          objectRef: id?.objectRef,
          biller: id?.biller

        },
        commit
      ).then(({ done,status, data }) => {
        if (done) {
          commit("setRequestPaymentId", data);

          return true;
        }
        var invoices = [];
        data?.map((i) => {
          if (i?.id) invoices.push(i);
          else invoices?.push(i?.invoice);
        });
        dispatch("addItemToCards", formatInvoices(invoices));
        if(status == '500'){
          return status
        }
        return false;
      });
      console.log("dataToResturn",dataToResturn)
      return dataToResturn
    },
    async checkPaymentExist({ commit ,state}) {
      return fetchApi(
        "GET",
        "/payments/" + state.paymentId,
        null,
        commit
      ).then(({ done, data }) => {
        console.log('welcome err',{ done, data })
        if (done) {
          if (data.error) {
            Vue.$toast.error(i18n.t("errPay"), {
              canTimeout: true,
              duration : 5000
        
          });   
            console.log('hellllo',data.error)
            return false;
          }
          return data;
        }
      });
    },

    async checkPaymentExistService({ commit },payload) {
      return fetchApi(
        "GET",
        "/payments/" +payload,
        null,
        commit
      ).then(({ done, data }) => {
        console.log('welcome err',{ done, data })
        if (done) {
          if (data.error) {
            Vue.$toast.error(i18n.t("errPay"), {
              canTimeout: true,
              duration : 5000
        
          });   
            console.log('hellllo',data.error)
            return false;
          }
  
          return data;
        }
      });
    },
    async checkPayment({ commit, state }, payload) {
      let data = {};
      data.method = payload;
      data.id = state.paymentId;
      data.pan="*****";
      const paymentData= await fetchApi("GET", "/payment", data, commit).then((response) => {
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy", response);
        return response;
      });
      return paymentData
    },
    async sendEmail({ commit }, payload) {
      return await fetchApi("POST", "../contact-us", payload, commit).then(
        (res) => res.done
      );
    },
    async openPaymentWindow({ dispatch,commit, state }, dataPay) {
      const paymentId = state.paymentId;
      console.log("paymentId",paymentId)
     
      console.log("isPaid",dataPay.id);
      console.log(dataPay.url);
      const popupRef = window.open(
        dataPay.url,
        "pay",
        "popup"
      );
      console.log(popupRef);
      dispatch("initPayment", true);
      const timer = setInterval(async function () {
    
        if (popupRef.closed) {
          const isPaid =   await dispatch ("checkPaymentExist")
          console.log(isPaid);

          // await dispatch("getFromLocalStorage");
          if (isPaid) {
                      
            if (isPaid?.status === "paid") {

              const invoiceData = JSON.parse(
                localStorage.getItem("invoices")
              );
            console.log("mrx alldata",invoiceData)
                          
              let newInvoices = invoiceData.map(e => {
              
                if(e.id === dataPay.id){
                  const newPayment = {
                    "transactionCode": isPaid?.transactionCode,
                    "transactionStatus": isPaid?.status === "paid" ? "PAID" :"",
                    "transactionReference": isPaid?.transactionReference,
                    "paymentAmount": isPaid?.amount,

                    "paymentDate": isPaid?.paymentDate,
                    "sourceAccount": isPaid?.sourceAccount,
                    "destinationAccount": isPaid?.destinationAccount,
                    "paymentReceipt": {},
                    "paymentCanal": isPaid?.paymentCanal,
                    "paymentMode": isPaid?.method,
                    "paymentMean": isPaid?.paymentMean
                  };
                  e.paymentStatus ="PAID"
                  e.paymentsList.push(newPayment);
                 
                }
                console.log('mrx paid', e)
                return e
              })
              localStorage.setItem("invoices", JSON.stringify(newInvoices));
              commit("setCards", formatInvoices(newInvoices));
              // commit("setServices", newServices);
              // commit("setService", newServices?.find(e=>e.id === obj?.id));
              Swal.fire({
                icon: "success",
                text: "Facture payée avec succès ",
                confirmButtonColor: "#1db7c2",
                confirmButtonText: "Fermer",
              });

            }
         
          else {
           Vue.$toast.error(i18n.t("errPay"), {
             canTimeout: true,
             duration : 5000
         })
         }}
          localStorage.setItem("start", true);
         
          clearInterval(timer);
        }
      }, 500);
     
    },
    removeOneItem({ commit }, data) {
      let invoiceData = JSON.parse(localStorage.getItem("invoices"));
      if(invoiceData.length>1){
        let supp=invoiceData.filter(e=>e.id !== data?.id)
        commit('setInvoices', supp)
        localStorage.setItem("invoices", JSON.stringify(supp));
      }else{
        commit('setInvoices', [])
        localStorage.setItem("invoices", []);
      }
    },
    removeAll({ commit}) {
      commit('setInvoices', [])
      localStorage.removeItem("invoices")
    },
    removeOneRecharge({ commit }, data) {
      let rechargeData = JSON.parse(localStorage.getItem("recharges"));
      if(rechargeData.length>1){
        let supp=rechargeData.filter(e=>e.id !== data?.id)
        commit('setRecharges', supp)
        localStorage.setItem("recharges", JSON.stringify(supp));
      }else{
        commit('setRecharges', [])
        commit('setRecharge', {})
        localStorage.setItem("recharges", []);
      }
    },
    removeOneService({ commit }, data) {
      let rechargeData = JSON.parse(localStorage.getItem("services"));
      if(rechargeData.length>1){
        let supp=rechargeData.filter(e=>e.id !== data?.id)
        commit('setServices', supp)
        localStorage.setItem("services", JSON.stringify(supp));
      }else{
        commit('setServices', [])
        commit('setService', {})
        localStorage.setItem("services", []);
      }
    },
    removeAllRecharges({ commit}) {
      commit('setRecharges', [])
      commit('setRecharge', {})
      localStorage.setItem("recharges", []);
    },
  },
  modules: {
    card,
  },
});

function getSearhError(message) {
  return (
    {
      "Invalid search parameters": {
        type: "error",
        message: " Paramètres de recherche invalides. Veuillez réessayer",
      },
      default: {
        type: "error",
        message: " Paramètres de recherche invalides. Veuillez réessayer",
      },
      "Invoices not found": {
        type: "error",
        message: "Aucune facture ou Abonnement trouvée",
      },
      "steg error": {
        type: "error",
        message: "Une erreur technique est survenue. Veuillez réessayer.",
      },
      error: {
        type: "error",
        message: "Une erreur technique est survenue. Veuillez réessayer.",
      },
      empty: {
        type: "info",
        message: "Aucune facture ou Abonnement trouvée",
      },
    }[message || "default"] || { message, type: "error" }
  );
}

function formatInvoices(invoices) {
  return invoices.map((el) => ({
    ...el,
    key: el.biller + "/" + el.invoiceRef,
  }));
}
