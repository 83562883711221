 <template>
    <div>
     <h5 class="text-center">
          {{ $t("cgu.titre") }}
    </h5>
      <v-row justify="center" class="pt-2">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article1Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article1Text") }} <br> {{$t("cgu.article1Text1") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article2Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article2Text") }} <br> {{$t("cgu.article2Text1") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article3Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article3Text") }} <br> {{$t("cgu.article3Text1") }} <br> {{$t("cgu.article3Text2") }}
              <br> {{$t("cgu.article3Text3") }} <br> {{$t("cgu.article3Text4") }} <br> {{$t("cgu.article3Text5") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article4Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article4Text") }} <br> {{ $t("cgu.article4Text1") }} 
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article5Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article5Text") }} <br> {{ $t("cgu.article5Text1") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article6Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article6Text") }} <br> {{ $t("cgu.article6Text1") }} 
              <br> {{ $t("cgu.article6Text2") }}<br> {{ $t("cgu.article6Text3") }}
              <br> {{ $t("cgu.article6Text4") }}<br> {{ $t("cgu.article6Text5") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
           <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article7Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article7Text") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
           <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article8Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article8Text") }} <br>{{ $t("cgu.article8Text1") }}
              <br>{{ $t("cgu.article8Text2") }}<br>{{ $t("cgu.article8Text3") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
           <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article9Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article9Text") }} <br> {{ $t("cgu.article9Text1") }}
              <br>{{ $t("cgu.article9Text2") }}  <br> {{ $t("cgu.article9Text3") }}
               <br> {{ $t("cgu.article9Text4") }} <br> {{ $t("cgu.article9Text5") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
           <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article10Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article10Text") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
           <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article11Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article11Text") }}
            </v-expansion-panel-content>
             <v-expansion-panel>
            <v-expansion-panel-header class="panel_title">{{
              $t("cgu.article12Titre")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="panel_body">
              {{ $t("cgu.article12Text") }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <div class="mt-2 d-flex">
            <img class="my-1 mx-auto w-50"
            src="@/assets/monetique.png"
      />
    </div>
    </div>
  </template>
  <script>
  export default {
    components: {},
    computed: {},
  };
  </script>
  <style scoped lang="scss">
  .TitreFoir {
    position: relative;
    font-family: "Arial";
    font-size: 50px;
    line-height: 100px;
    text-align: center;
  }
  .imgFAQ {
    width: 11%;
    margin-left: 16%;
    margin-top: 1%;
  }
  .contentFAQ {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .panel_body {
    text-align: justify;
    font-size: 12px;
  }
  .panel_title {
    color: #2f3638;
  }
  </style>